<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                        <v-card-title class="title">
                            Receipt
                            <v-spacer></v-spacer>
                        </v-card-title>
                    <v-card outlined>
                        <v-card-title class="title pb-0">
                            <v-flex xs2>
                                <v-text-field :disabled="!sodEnable" placeholder="ST-76001" v-model="enrollCode"
                                              class="pa-0"
                                              label="Enroll Code"></v-text-field>
                            </v-flex>
                            <v-flex xs5>
                                <v-autocomplete
                                        :disabled="!sodEnable"
                                        :search-input.sync="search"
                                        :items="students"
                                        @keyup="searchEnable=true"
                                        @change="searchEnable=false"
                                        hide-no-data
                                        clearable
                                        :loading="studentLoading"
                                        class="pa-0"
                                        label="Search Students"
                                        v-model="form.name"
                                        :hint="!students.length?'Type atleast 3 characters':''"
                                />
                            </v-flex>
                            <v-flex xs3>

<!--                                <transition name="animate-css-transition"-->
<!--                                            enter-active-class="animated fadeInRight"-->
<!--                                            leave-active-class="animated fadeOutRight">-->
<!--                                    <v-btn v-if="students.length || enrollCode.length"-->
<!--                                           fab-->
<!--                                           small-->
<!--                                           @click="enrollCode='',students=[], record=[],form.name=null,search=null"-->
<!--                                           style="z-index: 1;"-->
<!--                                           class="error">-->
<!--                                        <v-icon dark>close</v-icon>-->
<!--                                    </v-btn>-->
<!--                                </transition>-->

                                <transition name="animate-css-transition"
                                            enter-active-class="animated fadeInRight"
                                            leave-active-class="animated fadeOutRight">
                                    <v-btn v-if="students.length || enrollCode.length"
                                           fab
                                           small
                                           @click="searchStudent"
                                           style="z-index: 1;"
                                           class="success">
                                        <v-icon dark>search</v-icon>
                                    </v-btn>
                                </transition>
                            </v-flex>
                        </v-card-title>

                    </v-card>
                    <v-container>
                        <strong v-if="!sodEnable" style="color:#ff5252;font-size: 16px;">
                            <v-icon color="#ff5252">warning</v-icon>
                            {{sod_date}}</strong>
                        <strong v-if="sodEnable">
                            <v-icon small>date_range</v-icon>
                            Transaction Date : {{sod_date}}</strong>
                    </v-container>
                    <v-data-table :headers="headers" hide-default-footer :items="record"
                                  v-model="selected"
                                  :expand="expand"
                                  select-all
                                  :loading="form.loading">

                        <template v-slot:headers="props">
                            <tr>
                                <th></th>
                                <th>
                                    <v-checkbox
                                            :input-value="props.all"
                                            :indeterminate="props.indeterminate"
                                            primary
                                            hide-details
                                            @click.stop="toggleAll"
                                    ></v-checkbox>
                                </th>
                                <th
                                        v-for="header in props.headers"
                                        :key="header.text"
                                >
                                    {{ header.text }}
                                </th>
                            </tr>
                        </template>
                        <template v-slot:items="props">
                            <tr>
                                <td :class="'highlight'">
                                    <span v-if="props.selected"
                                          @click="props.expanded = !props.expanded, props.item.flag = props.expanded">
                                        <v-icon style="cursor: pointer" color="error" v-if="props.expanded">
                                            indeterminate_check_box
                                        </v-icon>
                                        <v-icon style="cursor: pointer" color="primary" v-else>add_circle
                                        </v-icon>
                                    </span>
                                    <span v-else>
                                        <v-icon style="cursor: no-drop">
                                            add_circle
                                        </v-icon>
                                    </span>
                                </td>
                                <td :active="props.selected" @click="props.selected = !props.selected">
                                    <v-checkbox
                                            :input-value="props.selected"
                                            primary
                                            hide-details
                                    ></v-checkbox>
                                </td>
                                <td class="text-xs-center">{{ props.index +1 }}</td>
                                <td class="text-xs-center">{{ props.item.invoice_no }}</td>
                                <td class="text-xs-center">{{ props.item.monthname }}</td>
                                <td class="text-xs-center">{{ props.item.bill_amount.currency() }}</td>
                                <td class="text-xs-center">
                                    <input autocomplete="off" class="boxField"
                                           @keyup="calculation()"
                                           v-model="record[props.index].instant_discount"
                                           :disabled="props.item.enable || bulk"/>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:expand="props">
                            <v-card style="width: 100%;" v-if="props.selected">
                                <table width="100%" class="inner-table">
                                    <thead>
                                    <tr>
                                        <th class="text-xs-left"><strong>Fee Head</strong></th>
                                        <th class="text-xs-left"><strong>Amount</strong></th>
                                        <th class="text-xs-left"><strong>Payment Amount</strong></th>
                                        <th class="text-xs-left"><strong>Discount</strong></th>
                                    </tr>
                                    <tr v-for="(x,i)  in props.item.details" :key="i" style="height: 0px">
                                        <td class="text-xs-left">{{x.fee_head}}</td>
                                        <td class="text-xs-left">{{x.due_amount}}</td>
                                        <td class="text-xs-left">
                                            <input autocomplete="off" class="boxField"
                                                   @keyup="calculation()"
                                                   v-model="x.amount"/>

                                        </td>
                                        <td class="text-xs-left">
                                            <input autocomplete="off" class="boxField"
                                                   @keyup="calculation()"
                                                   v-model="x.instant_discount" :disabled="bulk"/>
                                        </td>
                                    </tr>
                                    </thead>
                                </table>
                            </v-card>
                        </template>
                        <template slot="footer" v-if="record.length">
                            <tr>
                                <th colspan="5" class="text-xs-right">Total Amount</th>
                                <th class="text-xs-center">{{totalSum.currency()}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th colspan="5" class="text-xs-right">Total Selected Amount</th>
                                <th class="text-xs-center">{{selectedSum.currency()}}</th>
                                <th></th>
                            </tr>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th>Bulk</th>
                                <th class="text-xs-right">
                                    <v-checkbox
                                            primary
                                            hide-details
                                            v-model="bulk"
                                    ></v-checkbox>
                                </th>
                                <th class="text-xs-right">
                                    <span v-if="!bulk" style="color: #8b8686">Discount</span>
                                    <span v-else>Discount</span>
                                </th>
                                <th class="text-xs-center">
                                    <input autocomplete="off" class="boxField" label="Bulk"
                                           v-model="discount" @keyup="discountCal" :disabled="!bulk"/>
                                </th>
                            </tr>
                            <tr>
                                <th colspan="6" class="text-xs-right">Amount to Pay</th>
                                <th class="text-xs-center">
                                    <input autocomplete="off" class="boxField"
                                           v-model="amount_to_pay" @change="amountToPayChange" :disabled="selectedSum < totalSum"/>
                                </th>
                            </tr>
                            <tr v-if="amount_to_pay > totalSum" style="color: red">
                                <th colspan="6" class="text-xs-right">Advance</th>
                                <th class="text-xs-center">
                                    {{(amount_to_pay - totalSum).currency()}}
                                </th>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-card-title v-if="record.length">
                        <v-flex xs12 sm12 class="text-xs-right">
                            <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                                        leave-active-class="animated fadeOutRight">
                                <v-btn style="width: 100%" v-if="$auth.can('receipt-create')" outlined color="primary"
                                       @click="confirmDialog=true" ma-0
                                       :disabled="amount_to_pay < 0">Pay
                                </v-btn>
                            </transition>
                        </v-flex>
                    </v-card-title>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="confirmDialog" persistent max-width="650px">
            <v-card>
                <v-card-title class="title pa-3 warning white--text">
                    <v-icon class="mr-2 white--text">warning</v-icon>
                    Please, wait!
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-chip>{{parseFloat(amount_to_pay).currency()}}</v-chip>
                    <v-chip color="green" text-color="white">{{convertNumberToWords(amount_to_pay, true)}}</v-chip>
                    <br>
                    Are you sure you want to pay this amount?
                </v-card-text>
                <v-card-text>
                    <v-layout row wrap>
                        <v-flex xs4 sm4 class="bankInfo">
                            <v-select :items="paymentMethod" label="Payment Method"
                                      v-model="payment_method"/>
                        </v-flex>
                        <v-flex xs4 sm4 v-if="payment_method === 'cheque'" class="bankInfo">
                            <v-text-field v-model="cheque_no" label="Cheque No"></v-text-field>
                        </v-flex>
                        <v-flex xs4 sm4 v-if="payment_method === 'cheque'" class="bankInfo" style="margin-right: -15px">
                            <v-text-field v-model="bank_name" label="Bank Name"></v-text-field>
                        </v-flex>
                        <v-flex xs12 sm12>
                            <v-textarea rows="2"
                                        v-model="remarks"
                                        label="Remarks"
                                        hint="Comment any other information"
                            ></v-textarea>
                        </v-flex>

                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" outlined small
                           @click="confirmDialog = false,remarks= '',payment_method='cash'">Cancel
                    </v-btn>
                    <v-btn color="warning" outlined small @click="submit" :disabled="!payment_method">Confirm</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="alertDialog" persistent max-width="460px">
            <v-card>
                <v-card-title class="title pa-3 error white--text">
                    <v-icon class="mr-2 white--text">warning</v-icon>
                    Please, wait!
                </v-card-title>
                <v-card-text class="ma-0">
                    Sod date is not set for today's Transaction . Are you sure want to continue with this <span
                        style="color: red"> {{sod_date}} </span>date?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="warning" outlined small @click="alertDialog = false">Cancel</v-btn>
                    <v-btn color="success" outlined small @click="alertDialog = false">Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'

    import {convertNumberToWords, formatNumber} from '@/library/helpers'
    // import {formatNumber} from '@/library/helpers'
    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                bill_month: '',
                grade_id: '',
                enroll: '',
                name: ''

            }, '/api/invoice'),
            selected: [],
            discount: 0,
            expand: false,
            bulk: true,
            record: [],
            amount_to_pay: 0,
            selectedSum: 0,
            search: null,
            headers: [
                {text: 'S. No', align: 'left', value: 'id', sortable: false},
                {text: 'Invoice No', align: 'left', value: 'invoice_no', sortable: false},
                {text: 'Bill Months', align: 'left', value: 'monthname', sortable: false},
                {text: 'Due Amount', align: 'left', value: 'bill_date', sortable: false},
                {text: 'Discount', align: 'left', value: 'discount', sortable: false},
            ],
            paymentMethod: [
                {text: 'Cash', value: 'cash'},
                {text: 'Cheque', value: 'cheque'},

            ],
            grades: [],
            today: nd.format('YYYY-MM-DD'),


            studentData: false,
            studentLoading: false,
            payment_method: 'cash',
            enrollCode: '',
            enroll_id: '',
            months: [],
            month: '',
            studentSection: '',
            enrollId: '',
            generate: false,
            print: false,
            billingSetting: {},
            confirmDialog: false,
            items: [],
            name: '',
            input: false,
            roll: null,
            grade: null,
            section: null,
            fee_heads: [],
            datavalue: false,
            bankInfo: false,
            remarks: '',
            bank_name: '',
            cheque_no: '',
            sod_date: '',
            sodEnable: false,
            paymentDetail: [
                {ids: '', amount: 0, 'discount': 0},
            ],
            adv: '',
            totalSum: 0,
            students: [],
            alertDialog: false,
            searchEnable: true,
            remainingAdvance: 0,

        }),

        computed: {
            ...mapState(['batch']),

        },

        mounted() {
            this.currentSod();
            // this.searchStudent();
        },

        watch: {
            'batch': function (value) {
            },
            'enrollCode': function (value) {
                this.studentData = false;
                this.form.name = '';
                if (value && value.length > 7) {
                    this.searchStudent();
                }
            },
            search(val) {
                console.log('testing...');
                if (!this.searchEnable) return false;
                if (!val) {
                    this.students = [];
                    this.studentLoading = false;
                    return;
                }
                if (this.isLoading) return;
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.studentLoading = true;
                this.timer = setTimeout(() => {
                    this.$rest.get('api/search-student-grade-section?search=' + val)
                        .then(({data}) => {
                            this.students = data.data.map(item => {
                                this.enroll_id = item.enroll_id;
                                return {
                                    value: item.enroll_code,
                                    text: item.name + ' (' + item.grade + '-' + item.section + ') - ' + item.roll,
                                    enrollCode: item.enroll_code,
                                    enrollId: item.enroll_id,
                                    sectionId: item.section_id,
                                }
                            });
                            // console.log(data);
                            // this.remainingAdvance = data.data.advance
                        }).catch(err => {
                    }).finally(() => (this.studentLoading = false))
                }, 500);

            },
            'selected': function (v) {
                this.calculation();
            },
            'form.name': function (value) {
                this.students.filter(res => {
                    if (res.value == value) {
                        this.enrollCode = res.enrollCode;
                        this.enrollId = res.enrollId;
                        this.studentSection = res.sectionId
                    }
                })
                this.record = [];
            },
            'bulk': function (v) {
                if (v) {
                    this.record.map(res => {
                        res.instant_discount = 0
                    })
                }
                this.calculation();
            },
        },

        methods: {
            convertNumberToWords,
            searchStudent() {
                this.$rest.get('/api/invoice/student?enrollId=' + this.enrollId + '&sectionId=' + this.studentSection).then(({data}) => {
                        // this.$rest.get('/api/invoice/student?enrollId=174&sectionId=35').then(({data}) => {
                        this.selected = [];
                        this.totalSum = 0;
                        this.amount_to_pay = 0;
                        this.record = data.data;
                        this.record.map(res => {
                            res.bill_amount = 0;
                            res.instant_discount = 0;
                            res.details.map(detail => {
                                detail.amount = detail.due_amount;
                                res.bill_amount += detail.due_amount;
                            })
                            res.enable = false;
                            this.totalSum += res.bill_amount;
                            this.amount_to_pay += res.bill_amount;
                            this.selectedSum += res.bill_amount;

                            this.selected.push(res);
                        });
                    }
                ).catch(e => {
                    this.$events.fire('notification', {message: 'No record found.', status: 'error'});
                })
            },
            // indvidialDiscountChange() {
            //     this.calculation()
            // },
            save() {
                if (this.payment_method === 'cheque' && (!this.cheque_no || !this.bank_name)) {
                    this.$events.fire('notification', {
                        message: 'Please fill the  Bank/Cheuqe information correctly.',
                        status: 'error'
                    })
                } else {
                    this.confirmDialog = true;
                }
            }
            ,
            submit() {
                if (this.payment_method === 'cheque' && (!this.cheque_no || !this.bank_name)) {
                    this.$events.fire('notification', {
                        message: 'Please fill the  Bank/Cheuqe information correctly.',
                        status: 'error'
                    })
                } else {
                    this.$rest.post('/api/fee-payment-v2', {
                        bulk: this.bulk ? 'true' : 'false',
                        discount_amount: this.discount,
                        enroll_id: this.enroll_id,
                        total_amount: this.amount_to_pay,
                        invoices: this.selected,
                        via: this.payment_method,
                        advance: this.amount_to_pay > this.totalSum ? this.amount_to_pay - this.totalSum : 0,
                        cheque_no: this.cheque_no,
                        bank_name: this.bank_name,
                        sod_date: this.sod_date,
                        remarks: this.remarks,
                    }).then(({data}) => {
                        this.$events.fire('notification', {
                            message: data.message,
                            status: 'success'
                        })
                        this.enrollCode = '';
                        this.form.name = '';
                        this.record = [];
                        this.search = '';
                        this.remarks = '';
                        this.payment_method = 'cash'
                        this.confirmDialog = false;
                        this.expand = false;
                        this.bulk = true;
                        this.students = [];
                        window.open(data.data.url)
                    }).catch(e => {
                        this.$events.fire('notification', {
                            message: e.message,
                            status: 'error'
                        })
                        this.confirmDialog = false;
                    })
                }
            }
            ,
            toggleAll() {
                if (this.selected.length) this.selected = []
                else this.selected = this.record.slice()
            }
            ,
            currentSod() {
                this.$rest.get('/api/sod-current').then(({data}) => {
                    if (data.sod) {
                        this.sodEnable = true;
                        this.sod_date = data.sod;
                        if (this.today != this.sod_date) this.alertDialog = true;
                    } else {
                        this.sodEnable = false;
                        this.sod_date = 'Start of day has not been set. Please contact your admin.'
                    }
                });

            },
            calculation() {
                this.amount_to_pay = 0;
                this.discount = 0;
                this.selectedSum = 0;
                let ids = [];
                this.record.map(res => {
                    res.details.map(detail => {
                        if (parseFloat(detail.amount) > parseFloat(detail.due_amount)) {
                            detail.amount = detail.due_amount;
                        }
                        if (parseFloat(detail.instant_discount) > parseFloat(detail.due_amount)) {
                            detail.instant_discount = detail.due_amount;
                        }
                    })
                    if (parseFloat(res.instant_discount) > parseFloat(res.bill_amount)) {
                        res.instant_discount = res.bill_amount
                    }

                })
                this.selected.map(res => {
                    ids.push(res.id)
                    this.selectedSum += parseFloat(res.bill_amount)
                    this.amount_to_pay += parseFloat(res.bill_amount)
                    if (res.flag) {
                        res.instant_discount = 0;
                        this.selectedSum = 0;
                        this.amount_to_pay = 0;
                    }
                    res.details.map(detail => {
                        if (res.flag) {
                            this.selectedSum += parseFloat(detail.amount);
                            this.amount_to_pay += parseFloat(detail.amount);
                            res.instant_discount += parseFloat(detail.instant_discount);
                        } else {
                            detail.amount = detail.due_amount;
                            detail.instant_discount = 0;
                        }
                    })

                });
                this.record.map(res => {
                    if (ids.includes(res.id)) {
                        res.enable = false;
                    } else {
                        res.enable = true;
                        res.instant_discount = 0;
                    }
                    this.discount += parseFloat(res.instant_discount ? res.instant_discount : 0);
                })
                this.amount_to_pay = parseFloat(this.selectedSum) - parseFloat(this.discount);

                if(['',NaN,undefined].includes(this.amount_to_pay)){
                    this.amount_to_pay = 0
                }
                // this.selectedSum = parseFloat(this.selectedSum);

            },
            discountCal() {
                this.amount_to_pay = parseFloat(this.selectedSum) - parseFloat(this.discount);
                if(['',NaN,undefined].includes(this.amount_to_pay)){
                    this.amount_to_pay = 0
                }
            },
            amountToPayChange() {
                if (this.amount_to_pay < this.selectedSum) {
                    this.amount_to_pay = this.selectedSum;
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    table.v-datatable.v-table.theme--light {
        width: 100%;
    }

    .amountPayable {
        position: absolute;
        margin-top: 23px;
        font-size: 10px;
        font-weight: 600;
        margin-left: -80px;
    }

    .boxField {
        border: 1px solid #ccc;
        font-weight: bold;
        border-radius: 3px;
        width: 80px;
        height: 25px !important;
        text-align: center !important;
    }

    .boxField:disabled {
        background: #bfbaba3d;
        cursor: no-drop;
    }

    .bankInfo {
        margin-left: 5px;
        width: 100%;
    }

    .highlight {
        td {
            font-weight: bold !important;
        }
    }

    tr.highlight {
        cursor: pointer;
    }
</style>